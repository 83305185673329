import { useMedplum, useResource } from '@medplum/react';
import { Account, Extension, Reference, Resource } from '@medplum/fhirtypes';
import { useEffect, useState, useCallback, useRef, useMemo } from 'react';

interface SubscriptionFeatures {
  smsMessagesPerMonth: number;
  voiceMinutesPerMonth: number;
  maxDailyCheckins: number;
  includedDevices: string[];
  hasAdvancedAnalytics: boolean;
  hasEarlyAccess: boolean;
}

interface SubscriptionDetails {
  planId: string;
  status: string;
  startDate: string;
  features: SubscriptionFeatures;
}

// Cache configuration
const CACHE_TTL = 5 * 60 * 1000; // 5 minutes
const subscriptionCache = new Map<string, {
  data: SubscriptionDetails | null;
  timestamp: number;
  accountId: string;
}>();

// Polling intervals for different states
const POLLING_INTERVALS = {
  WAITING_FOR_SUBSCRIPTION: 5 * 1000,    // 5 seconds when waiting for subscription
  ACTIVE_SUBSCRIPTION: 60 * 60 * 1000,   // 60 minutes when subscription is active
  UNAUTHENTICATED: null,                 // Don't poll when unauthenticated
} as const;

export function useSubscription() {
  const medplum = useMedplum();
  const profile = medplum.getProfile();
  const [isLoadingAccount, setIsLoadingAccount] = useState(true);
  const [subscription, setSubscription] = useState<SubscriptionDetails | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [accountId, setAccountId] = useState<string | null>(null);
  
  const pollIntervalRef = useRef<number | null>(null);
  const account = useResource<Account>(accountId ? { reference: `Account/${accountId}` } : undefined);

  const fetchSubscription = useCallback(async (forceFetch = false) => {
    if (!profile?.id) {
      setIsLoadingAccount(false);
      return;
    }

    console.log('fetch is called');

    try {
      // Check cache first if not forcing a fetch
      if (!forceFetch) {
        const cached = subscriptionCache.get(profile.id);
        const now = Date.now();
        
        if (cached && 
            (now - cached.timestamp < CACHE_TTL) && 
            (!accountId || cached.accountId === accountId)) {
          setSubscription(cached.data);
          setIsLoadingAccount(false);
          return;
        }
      }

      // Fixed search query format
      const searchResult = await medplum.searchOne('Account', {
        subject: `Patient/${profile.id}`,
        'type': 'http://terminology.hl7.org/CodeSystem/v3-ActCode|PBILLACCT',
        status: 'active'
      });

      const accountResource = searchResult as Account | undefined;
      
      if (!accountResource) {
        setSubscription(null);
        updateCache(profile.id, null, '');
        setIsLoadingAccount(false);
        console.log(`not acct resource`);
        return;
      }

      if (accountResource) {
        setAccountId(accountResource.id || null);
        const details = parseSubscriptionDetails(accountResource);
        
        setSubscription(details);
        if (profile?.id) {
          updateCache(profile.id, details, accountResource.id || '');
        }
      }

    } catch (err) {
      setError(err instanceof Error ? err : new Error('Unknown error'));
      console.error('Error fetching subscription:', err);
    } finally {
      setIsLoadingAccount(false);
    }
  }, [medplum, profile?.id, accountId]);

  // Function to update polling interval based on current state
  const updatePollingInterval = useCallback(() => {
    // Clear existing interval
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
      pollIntervalRef.current = null;
    }

    // Determine appropriate polling interval
    let pollingInterval: number | null = null;

    if (!profile) {
      pollingInterval = POLLING_INTERVALS.UNAUTHENTICATED;
    } else if (!subscription?.status || !isActiveSubscription(subscription.status)) {
      pollingInterval = POLLING_INTERVALS.WAITING_FOR_SUBSCRIPTION;
    } else {
      pollingInterval = POLLING_INTERVALS.ACTIVE_SUBSCRIPTION;
    }

    // Set up new interval if needed
    if (pollingInterval) {
      pollIntervalRef.current = window.setInterval(() => {
        fetchSubscription(true);
      }, pollingInterval);
    }
  }, [profile, subscription?.status, fetchSubscription]);

  // Initial fetch and cache setup
  useEffect(() => {
    fetchSubscription();
  }, [fetchSubscription]);

  // Update polling interval whenever relevant state changes
  useEffect(() => {
    updatePollingInterval();
    
    // Cleanup function
    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
      }
    };
  }, [updatePollingInterval]);

  // React to Account resource updates from Medplum
  let details: SubscriptionDetails | null = null;
  useEffect(() => {
    if (account && 'resourceType' in account && account.resourceType === 'Account') {
      details =  parseSubscriptionDetails(account);
      setSubscription(details);
      if (profile?.id) {
        updateCache(profile.id, details, account.id || '');
      }
    }
  }, [account, profile?.id]);

  // Move hasActivePlan calculation inside the hook
  const hasActivePlan = useMemo(() => {
    if (isLoadingAccount) return null;
    return isActiveSubscription(subscription?.status);
  }, [isLoadingAccount, subscription?.status]);

  return {
    isLoadingAccount,
    subscription,
    error,
    hasActivePlan,
    refresh: () => fetchSubscription(true)
  };
}


// Add this helper function at the bottom with other helpers
function isActiveSubscription(status: string | undefined): boolean {
  if (!status) return false;
  const normalizedStatus = status.toLowerCase().trim();
  console.log('Checking subscription status:', {
    originalStatus: status,
    normalizedStatus,
    isActive: normalizedStatus === 'active' || normalizedStatus === 'trialing'
  });
  return normalizedStatus === 'active' || normalizedStatus === 'trialing';
}

// Helper functions remain the same
function updateCache(profileId: string, data: SubscriptionDetails | null, accountId: string) {
  subscriptionCache.set(profileId, {
    data,
    timestamp: Date.now(),
    accountId
  });
}

function findSubscriptionExtension(account: Account): Extension | undefined {
  return account.extension?.find(
    ext => ext.url === 'https://ramona.care/subscription-details'
  );
}

function findSubscriptionStatus(account: Account): string | undefined {
  // First check new format
  const statusExt = account.extension?.find(
    ext => ext.url === 'http://ramona.care/fhir/StructureDefinition/subscription-status'
  );
  if (statusExt?.valueString) {
    return statusExt.valueString;
  }

  // Fall back to old format
  const oldFormatExt = account.extension?.find(
    ext => ext.url === 'https://ramona.care/subscription-details'
  );
  
  if (oldFormatExt?.extension) {
    const statusSubExt = oldFormatExt.extension.find(ext => ext.url === 'status');
    return statusSubExt?.valueString;
  }

  return undefined;
}

function parseSubscriptionDetails(account: Account): SubscriptionDetails | null {
  // Check for new format first
  const planExt = account.extension?.find(
    ext => ext.url === 'http://ramona.care/fhir/StructureDefinition/subscription-plan'
  );
  const statusExt = account.extension?.find(
    ext => ext.url === 'http://ramona.care/fhir/StructureDefinition/subscription-status'
  );
  const trialEndExt = account.extension?.find(
    ext => ext.url === 'http://ramona.care/fhir/StructureDefinition/subscription-trial-end'
  );
  
  if (statusExt?.valueString) {
    // New format
    return {
      planId: planExt?.valueString || '',
      status: statusExt.valueString,
      startDate: trialEndExt?.valueDateTime || account.servicePeriod?.start || '',
      features: {
        smsMessagesPerMonth: getExtensionValue(account, 'sms-messages-per-month', 'valueInteger') || 0,
        voiceMinutesPerMonth: getExtensionValue(account, 'voice-minutes-per-month', 'valueInteger') || 0,
        maxDailyCheckins: getExtensionValue(account, 'max-daily-checkins', 'valueInteger') || 0,
        includedDevices: [], // Add if needed
        hasAdvancedAnalytics: getExtensionValue(account, 'has-advanced-analytics', 'valueBoolean') || false,
        hasEarlyAccess: getExtensionValue(account, 'has-early-access', 'valueBoolean') || false
      }
    };
  }

  // Fall back to old format
  const oldFormatExt = account.extension?.find(
    ext => ext.url === 'https://ramona.care/subscription-details'
  );

  if (!oldFormatExt?.extension) return null;

  const getValue = (url: string) => {
    const ext = oldFormatExt.extension?.find(e => e.url === url);
    return ext?.valueString || ext?.valueDateTime || '';
  };

  const features = getValue('features');

  return {
    planId: getValue('planId'),
    status: getValue('status'),
    startDate: getValue('startDate'),
    features: features ? JSON.parse(features) : null
  };
}

// Helper function to get extension values
function getExtensionValue(account: Account, urlSuffix: string, valueType: 'valueString' | 'valueInteger' | 'valueBoolean' | 'valueDateTime'): any {
  const ext = account.extension?.find(
    ext => ext.url === `http://ramona.care/fhir/StructureDefinition/${urlSuffix}`
  );
  return ext?.[valueType];
}