import React, { useState } from 'react';
import { 
  Container, Image, Paper, Title, Text, Button, Stack, Group, Radio, Badge,
  Card, Box,
  ThemeIcon
} from '@mantine/core';
import { IconArrowAutofitRight, IconArrowBarRight, IconArrowBigRight, IconArrowForward, IconArrowForwardUp, IconArrowForwardUpDouble, IconArrowNarrowRight, IconArrowsRight, IconCheck, IconShoppingCart } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import bpCuffImage from '@/img/ramona-bp-monitor.webp';
import logo from '@/img/ramona-logo-transparent.png'
import { useMedplum, useMedplumProfile } from '@medplum/react';
import { Patient } from '@medplum/fhirtypes';


export type PlanType = 'basic' | 'plus' | 'pro';

interface CheckoutFlowProps {
  recommendedPlan?: PlanType;
  recommendCuff?: boolean;
}

interface PlanData {
  id: string;
  name: string;
  description: string;
  features: string[];
  monthlyPrice: number;
  yearlyPrice: number;
  yearlyTotal: number;
}

export const PLAN_DATA: Record<PlanType, PlanData> = {
  basic: {
    id: 'basic',
    name: 'Basic',
    description: 'Essential features',
    features: ['100 SMS messages', '1 hour coaching'],
    monthlyPrice: 29,
    yearlyPrice: 290/12,
    yearlyTotal: 290
  },
  plus: {
    id: 'plus',
    name: 'Plus',
    description: 'Most popular choice',
    features: ['200 SMS messages', '2 hours coaching'],
    monthlyPrice: 49,
    yearlyPrice: 490/12,
    yearlyTotal: 490
  },
  pro: {
    id: 'pro',
    name: 'Pro',
    description: 'Complete package',
    features: ['Unlimited SMS messages', '3 hours coaching'],
    monthlyPrice: 109,
    yearlyPrice: 1090/12,
    yearlyTotal: 1090
  }
};

const CheckoutFlow: React.FC<CheckoutFlowProps> = ({ 
  recommendedPlan = 'plus',
  recommendCuff = true 
}) => {
    const profile = useMedplumProfile() as Patient;
    const medplum = useMedplum();
    const [billingCycle, setBillingCycle] = useState<'annual' | 'monthly'>('annual');
    const [selectedPlan, setSelectedPlan] = useState<PlanType>(recommendedPlan);
    const [includeCuff, setIncludeCuff] = useState(false);
    const [isBotLoading, setIsBotLoading] = useState(false);
    
    const getPlanPrice = (planId: PlanType) => {
      const plan = PLAN_DATA[planId];
      if (billingCycle === 'annual') {
        return {
          monthly: plan.yearlyPrice.toFixed(2),
          total: plan.yearlyTotal
        };
      }
      return {
        monthly: plan.monthlyPrice,
        total: plan.monthlyPrice
      };
    };
  
    const cuffPrice = 109;
    
    const calculateTotal = () => {
      const planPricing = getPlanPrice(selectedPlan);
      return includeCuff ? planPricing.total + cuffPrice : planPricing.total;
    };

    const initiateStripeCheckout = async () => {
        setIsBotLoading(true); // Show loading state
        const fullPlan = PLAN_DATA[selectedPlan];
        if (!fullPlan || !profile.id) {
            console.error("Plan not found or Patient ID missing.");
            setIsBotLoading(false);
            return;
        }
  
        console.log(`starting checkout: ${JSON.stringify(fullPlan)}`)
  
        try {
          const botSearchResult = await medplum.searchResources('Bot', {
            identifier: 'http://ramona.care/fhir/Bot|stripe-create-checkout'
          });
  
          if (!botSearchResult[0].id || botSearchResult.length === 0) {
            console.error("Stripe checkout bot not found");
            setIsBotLoading(false);
            return;
          }
          
          const addonIds = includeCuff ? ['blood-pressure-cuff'] : [];
          const operationResult = await medplum.executeBot(
            botSearchResult[0].id,
            {
              patientReference: `Patient/${profile.id}`,
              planId: fullPlan.id+'-'+billingCycle,
              addonIds: addonIds,
            }
          );
  
          if (!operationResult.checkoutUrl) {
              console.error("Medplum Bot Error:", operationResult);
              // Handle the error gracefully, show a message to the user
              setIsBotLoading(false);
              return;
          }
  
          // Assuming your bot returns the checkout URL in the response
          const checkoutUrl = operationResult.checkoutUrl;
  
          if (checkoutUrl) {
              window.location.href = checkoutUrl; // Redirect to Stripe Checkout
          } else {
              console.error("Checkout URL not found in Medplum Bot response.");
              setIsBotLoading(false);
              // Handle the error
              alert('Sorry, there was an error initiating the checkout process. Please try again or contact support@ramona.care if the problem persists.');
          }
  
        } catch (error) {
            console.error("Error calling Medplum Bot:", error);
            setIsBotLoading(false);
            // Handle the error
            alert('Sorry, there was an error starting the checkout process. Please try again or contact support@ramona.care if the problem persists.');
        }
      };

  
    return (
      <Container size="md"  bg={'gray.3'}>
      <Stack gap="lg">
        {/* Billing Section */}
        <Paper shadow="sm" p={{ base: 'xs', sm: 'md' }} radius="md" bg={'gray.2'}>
          <Title order={3} mb="md">Billing Cycle</Title>
          <Stack gap="sm">
          {['annual', 'monthly'].map((cycle) => {
            const isSelected = billingCycle === cycle;
            return (
                <Box key={cycle} pos="relative">
                <Card 
                    withBorder
                    radius="md"
                    p="md"
                    bg='gray.0'
                    onClick={() => setBillingCycle(cycle as 'annual' | 'monthly')}
                    style={{
                    cursor: 'pointer',
                    borderColor: isSelected ? 'var(--mantine-color-orange-8)' : undefined,
                    }}
                >
                    <Group justify="space-between" wrap="nowrap">
                    <div>
                        <Text fw={500}>{cycle === 'annual' ? 'Annual (Save 17%)' : 'Monthly'}</Text>
                        {cycle === 'annual' && <Text size="sm" c="dimmed">Best value - get 2 months free!</Text>}
                    </div>
                    </Group>
                </Card>
                {isSelected && (
                    <Badge 
                    color="orange"
                    variant='light'
                    size="xl"
                    style={{
                        position: 'absolute',
                        top: -13,
                        right: -8,
                        zIndex: 1
                    }}
                    p={0}
                    >
                    <ThemeIcon color="orange" radius="xl">
                        <IconCheck size="1rem"/>
                    </ThemeIcon>
                    </Badge>
                )}
                </Box>
            );
            })}
            </Stack>
         
           
        </Paper>

        {/* Plan Selection */}
        <Paper shadow="sm" p={{ base: 'xs', sm: 'md' }} radius="md" bg={'gray.2'}>
          <Title order={3} mb="md">Coaching Package</Title>
          <Stack gap="md">
            {['basic', 'plus', 'pro'].map((plan) => {
              const planType = plan as PlanType;
              const pricing = getPlanPrice(planType);
              const isRecommended = plan === recommendedPlan;
              const isSelected = selectedPlan === plan;
              
              return (
                // For billing cycle cards:
                <Box key={plan} pos="relative">
                <Card 
                    withBorder
                    bg='gray.0'
                    radius="md"
                    p="md"
                    onClick={() => setSelectedPlan(plan as PlanType)}
                    style={{
                        cursor: 'pointer',
                        borderColor: isSelected ? 'var(--mantine-color-orange-8)' : undefined
                    }}
                >
                    <Group justify="space-between" align="flex-start" wrap="nowrap">
                        <div>
                            <Group>
                                <Text fw={700}>{PLAN_DATA[planType].name}</Text>
                            </Group>
                            <Text size="sm" c="dimmed">{PLAN_DATA[planType].description}</Text>
                            {PLAN_DATA[planType].features.map((feature, index) => (
                                <Text key={index} size="sm" c="dimmed">• {feature}</Text>
                            ))}
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <Text fw={700} size="lg">${pricing.monthly}/mo</Text>
                            <Text size="sm" c="dimmed">
                                {billingCycle === 'annual' ? `$${pricing.total} billed annually` : 'Billed monthly'}
                            </Text>
                        </div>
                    </Group>
                </Card>
                {/* Recommended badge on left */}
                {isRecommended && (
                    <Badge 
                        color="orange"
                        size="md"
                        style={{
                            position: 'absolute',
                            top: -10,
                            left: 16,
                            zIndex: 1
                        }}
                    >
                        Recommended
                    </Badge>
                )}
                {/* Selected checkmark badge on right */}
                {isSelected && (
                    <Badge 
                        color="orange"
                        variant='light'
                        size="xl"
                        style={{
                            position: 'absolute',
                            top: -13,
                            right: -8,
                            zIndex: 1
                        }}
                        p={0}
                    >
                        <ThemeIcon color="orange" radius="xl">
                            <IconCheck size="1rem"/>
                        </ThemeIcon>
                    </Badge>
                )}
            </Box>
              );
            })}
          </Stack>
        </Paper>

        {/* Blood Pressure Cuff Add-on */}
        <Paper shadow="sm" p="md" radius="md" bg={'gray.2'}>
        <Title order={3} mb="md">Add-On</Title>
        <Stack gap="md">
            <Stack gap="sm">
            {[
                { value: true, label: 'Smart Blood Pressure Cuff', desc: '4G-connected device for instant tracking' },
                { value: false, label: 'No, thanks' }
                ].map((option) => {
                const isSelected = includeCuff === option.value;
                return (
                    <Box key={String(option.value)} pos="relative">
                    <Card 
                        withBorder
                        bg='gray.0'
                        radius="md"
                        p="md"
                        onClick={() => setIncludeCuff(option.value)}
                        style={{
                        cursor: 'pointer',
                        borderColor: isSelected ? 'var(--mantine-color-orange-8)' : undefined
                        }}
                    >
                        <Group justify="space-between" wrap="nowrap">
                        <div>
                            <Text fw={500}>{option.label}</Text>
                            {option.desc && <Text size="sm" c="dimmed">{option.desc}</Text>}
                        </div>
                        {option.value && <Text fw={700}>${cuffPrice}</Text>}
                        </Group>
                    </Card>
                    {isSelected && (
                        <Badge 
                        color="orange"
                        variant='light'
                        size="xl"
                        style={{
                            position: 'absolute',
                            top: -13,
                            right: -8,
                            zIndex: 1
                        }}
                        p={0}
                        >
                        <ThemeIcon color="orange" radius="xl">
                            <IconCheck size="1rem"/>
                        </ThemeIcon>
                        </Badge>
                    )}
                    {recommendCuff && option.value && (
                        <Badge 
                        color="orange"
                        size="md"
                        style={{
                            position: 'absolute',
                            top: -10,
                            left: 16,
                            zIndex: 1
                        }}
                        >
                        Recommended
                        </Badge>
                    )}
                    </Box>
                );
                })}
            </Stack>
        </Stack>
        </Paper>

        {/* Order Summary */}
        <Paper shadow="sm" p="md" radius="md" maw={600} bg="gray.0">
        <Title order={3} mb="md">Order Summary</Title>
        <Stack gap="xl"> {/* Increased gap between items */}
            <Stack gap="xl"> {/* Increased gap between line items */}
            {/* Plan Item */}
            <Group align="start" style={{ width: '100%' }}>
                <Box w={40} h={40} style={{ flexShrink: 0 }}> {/* Fixed width container */}
                <Image
                    src={logo}
                    width={40}
                    height={40}
                    radius="md"
                    fit="contain"
                />
                </Box>
                <Box style={{ flex: 1 }}>
                <Text fw={700}>
                    {selectedPlan.charAt(0).toUpperCase() + selectedPlan.slice(1)} 
                </Text>
                <Text size="sm">
                    {billingCycle === 'annual' ? 'Annual' : 'Monthly'}
                </Text>
                </Box>
                <Text style={{ minWidth: '80px' }} ta="right">
                ${getPlanPrice(selectedPlan).total}
                </Text>
            </Group>

            {/* BP Cuff Item */}
            {includeCuff && (
                <Group align="start" style={{ width: '100%' }}>
                <Box w={40} h={40} style={{ flexShrink: 0 }}> {/* Fixed width container */}
                    <Image
                    src={bpCuffImage}
                    width={40}
                    height={40}
                    radius="md"
                    fit="contain"
                    />
                </Box>
                <Box style={{ flex: 1 }}>
                    <Text fw={700}>Smart Blood Pressure Cuff</Text>
                    <Text size="sm">
                    Delivered in 2-4 business days
                    </Text>
                </Box>
                <Text style={{ minWidth: '80px' }} ta="right">
                    ${cuffPrice}
                </Text>
                </Group>
            )}

            {/* Total Section */}
            <Box style={{ 
                borderTop: '1px solid var(--mantine-color-gray-3)', 
                paddingTop: 'var(--mantine-gap-md)' // Increased padding
            }}>
                <Group justify="space-between" pt={12}> {/* Increased padding top */}
                <Text fw={700}>Total</Text>
                <Text fw={700} style={{ minWidth: '80px' }} ta="right">
                    ${calculateTotal()}
                </Text>
                </Group>
            </Box>
            </Stack>

            <Button 
            fullWidth
            color="orange"
            size="lg"
            rightSection={<IconArrowNarrowRight  />}
            onClick={() => initiateStripeCheckout()}
            loading={isBotLoading} // Add loading state
            disabled={isBotLoading} // Disable while loading
            >
            Checkout
            </Button>
            <Text mb={0} ta="center" size="sm" c="dimmed">
              All plans include a 10-day free trial. No charge until day 10, cancel anytime before then.</Text>
              <Text mt={0} ta="center" size="sm" c="dimmed">See our <Text component="a" inherit c="dimmed" href="https://www.ramona.care/refund-policy/" target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'underline' }}>Refund Policy</Text> for complete details.
            </Text>
        </Stack>
        </Paper>
      </Stack>
    </Container>
  );
};

export default CheckoutFlow;