import React, { useCallback, useEffect, useState } from 'react';
import { Form, useFieldAnswer } from '@quillforms/renderer-core';
import { registerCoreBlocks } from '@quillforms/react-renderer-utils';
import '@quillforms/renderer-core/build-style/style.css';
import { createReference } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import { RegisterForm } from '@/components/CustomRegisterForm';
import { Patient, QuestionnaireResponse } from '@medplum/fhirtypes';
import '../blocks/phoneblock';
import '../blocks/USMailingAddress';
import { parseQuillFormAnswers } from '../blocks/ParseQuillFormAnswers';
import classes from './TestFormPage.module.css';
import { FormObj } from '@quillforms/renderer-core/build-types/types';
import { getConfig } from '../config';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ramonaLogo from '../img/Ramona-Logo-Medium.webp'

import heroImage from '@/img/profile/ramona-hero.webp';
import startImage from '@/img/profile/entering-health-profile-ramona-ai.webp';
import comparisonImage from '@/img/profile/ramona-wellness-coaching-comparison.webp';
import roadImage from '@/img/profile/forest-trail-walk-sunrise-ramona-ai.webp';
import plannerImage from '@/img/profile/planner-in-kitchen-ramona-ai.webp';
import morningImage from '@/img/profile/morning-routine-newspaper-breakfast-ramona-ai.webp';
import { Box, Grid, Image } from '@mantine/core';
import { useMatomo } from '@/common/useMatomo';

registerCoreBlocks();

interface SubmissionDispatchers {
  completeForm: () => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setSubmissionErr: (error: string) => void;
}

// Define the Choice type
type Choice = {
  value: string;
  label: string;
};

type HealthGoal =
  | 'lose_weight'
  | 'gain_muscle'
  | 'improve_fitness'
  | 'reduce_stress'
  | 'improve_sleep'
  | 'eat_healthier'
  | 'other';

type BeforeGoingNextParams = {
  setIsFieldValid: (id: string, flag: boolean) => void;
  setFieldValidationErr: (id: string, err: string) => void;
  setIsPending: (flag: boolean) => void;
  setIsCurrentBlockSafeToSwipe: (flag: boolean) => void;
  goToBlock: (id: string) => void;
  goNext: () => void;
  currentBlockId: string;
  answers: Record<string, any>;
};

function AccountCreationForm({
  onAccountCreated,
}: {
  onAccountCreated: () => void;

}): JSX.Element {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState('');
  const medplum = useMedplum();
  const config = getConfig();

  const handleRegister = () => {
        onAccountCreated();  
  };

  return (
    <Grid> 
      <Grid.Col span="auto">
        <Box pt={100} pb={200} px={10}>
          <RegisterForm
            type="patient"
            projectId={config.projectId}
            googleClientId={config.googleClientId}
            recaptchaSiteKey={config.recaptchaSiteKey}
            onSuccess={() => handleRegister()}
          >
            <h3 >Let's Create Your Account</h3>
            
          </RegisterForm>
        </Box>
      </Grid.Col>
    </Grid>
  );
}

// Add this custom hook at the beginning of the file, before the component definitions
function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}

export function NewProfilePage(): JSX.Element {
  const medplum = useMedplum();
  const config = getConfig();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const { trackFormEvent } = useMatomo();

  const [surveyData, setSurveyData] = useState<Record<string, any> | null>(null);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);

  const primaryGoal = useFieldAnswer('primary_goal') as HealthGoal | undefined;
  const goalImportance = useFieldAnswer('goal_importance') as string[] | undefined;
  const motivation = useFieldAnswer('motivation') as number | undefined;
  const weightLossMethods = useFieldAnswer('weight_loss_methods') as string[] | undefined;
  const muscleGainDiet = useFieldAnswer('muscle_gain_diet') as string | undefined;
  const preferredActivities = useFieldAnswer('preferred_activities') as string[] | undefined;
  const stressSources = useFieldAnswer('stress_sources') as string[] | undefined;
  const stressReductionTechniques = useFieldAnswer('stress_reduction_techniques') as string[] | undefined;
  const sleepConcern = useFieldAnswer('sleep_concern') as string | undefined;
  const healthyEatingChallenge = useFieldAnswer('healthy_eating_challenge') as string | undefined;
  const dietaryChanges = useFieldAnswer('dietary_changes') as string[] | undefined;
  const nextStep = useFieldAnswer('next_step') as string[] | undefined;
  const potentialObstacles = useFieldAnswer('potential_obstacles') as string[] | undefined;
  const healthConditions = useFieldAnswer('health_conditions') as string[] | undefined;
  const focusArea = useFieldAnswer('focus_area') as string[] | undefined;
  

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 768; // mobile only breakpoint for now


  useEffect(() => {
    if (queryString && medplum.getProfile()) {
      const patient = medplum.getProfile() as Patient;
      const updatedPatient = {
        ...patient,
        extension: [
          ...(patient.extension || []),
          {
            url: 'http://ramona.care/fhir/StructureDefinition/query-string',
            valueString: queryString,
          },
        ],
      };
      medplum
        .updateResource(updatedPatient)
        .then(() => {
          console.log('Patient resource updated successfully');
        })
        .catch((error) => {
          console.error('Error updating patient resource:', error);
        });
    }
  }, [queryString, medplum]);

  const clickToContinue = (): void => {
    navigate('/');
  };

  const handleSubmit = useCallback(
    (data: Record<string, any>, dispatchers: SubmissionDispatchers) => {
      const { completeForm, setIsSubmitting, setSubmissionErr } = dispatchers;

      console.log('Submitted answers:', data);

      const profile = medplum.getProfile();

      if (profile) {
        // User is authenticated
        const patientReference = createReference(profile as Patient);
        const itemList = parseQuillFormAnswers(data.answers, formObj.blocks);
        const newResponse: QuestionnaireResponse = {
          resourceType: 'QuestionnaireResponse',
          status: 'completed',
          item: itemList,
          questionnaire: `Questionnaire/${config.onboardingId}`,
          subject: patientReference,
          source: patientReference,
          authored: new Date().toISOString(),
          author: patientReference,
        };

        setIsSubmitting(true);
        medplum
          .createResource(newResponse)
          .then((response) => {
            console.log('Created QuestionnaireResponse:', response);
            setIsSubmitting(false);
            completeForm();
            navigate('/select-plan'); // Redirect as needed
          })
          .catch((error) => {
            console.error('Error creating QuestionnaireResponse:', error);
            setSubmissionErr('An error occurred while submitting the form.');
            setIsSubmitting(false);
          });
      } else {
        // User is unauthenticated
        setSurveyData(data);
        setIsCreatingAccount(true);
      }
    },
    [medplum, config.onboardingId, navigate]
  );

  const handleAccountCreated = useCallback(() => {
    const profile = medplum.getProfile() as Patient;
    if (profile && surveyData) {
      const patientReference = createReference(profile);
      const itemList = parseQuillFormAnswers(surveyData.answers, formObj.blocks);
      const newResponse: QuestionnaireResponse = {
        resourceType: 'QuestionnaireResponse',
        status: 'completed',
        item: itemList,
        questionnaire: `Questionnaire/${config.onboardingId}`,
        subject: patientReference,
        source: patientReference,
        authored: new Date().toISOString(),
        author: patientReference,
      };

      medplum
        .createResource(newResponse)
        .then((response) => {
          console.log('Created QuestionnaireResponse:', response);
          setIsCreatingAccount(false);
          navigate('/select-plan'); // Redirect as needed
        })
        .catch((error) => {
          console.error('Error creating QuestionnaireResponse:', error);
        });
    }
  }, [medplum, surveyData, config.onboardingId, navigate]);

  const beforeGoingNext = ({
    setIsFieldValid,
    setFieldValidationErr,
    setIsPending,
    setIsCurrentBlockSafeToSwipe,
    goToBlock,
    goNext,
    currentBlockId,
    answers,
  }: BeforeGoingNextParams): void => {
    // Track the new block view
    const currentBlock = formObj.blocks.find(block => block.id === currentBlockId);
    if (currentBlock) {
      trackFormEvent(
        'Form Navigation',
        'View Block',
        `${currentBlock.name} - ${currentBlockId}`
      );
    }

    const setFieldValid = (): void => {
      setIsFieldValid(currentBlockId, true);
      setFieldValidationErr(currentBlockId, '');
      setIsCurrentBlockSafeToSwipe(true);
    };

    const setFieldInvalid = (errorMessage: string): void => {
      setIsFieldValid(currentBlockId, false);
      setFieldValidationErr(currentBlockId, errorMessage);
      setIsCurrentBlockSafeToSwipe(false);
    };

    switch (currentBlockId) {
      case 'dob':
        const dob = answers[currentBlockId].value as string;
        const currentDate = new Date();
        const eighteenYearsAgo = new Date(
          currentDate.getFullYear() - 18,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        const dobDate = new Date(dob);

        if (dobDate > eighteenYearsAgo) {
          setFieldInvalid('You must be 18 or older to use this service.');
        } else {
          setFieldValid();
          goNext();
        }
        break;

      default:
        setFieldValid();
        goNext();
    }
  };




  const formObj: FormObj = {
    blocks: [
      {
        name: 'welcome-screen',
        id: 'welcome',
        attributes: {
          label: 'Welcome to Your 5-Minute <i>Dial-In</i> Profile',
          description:
            "In the next few minutes, we'll help you:<div><ul style=\"text-align: start; margin-left:20px; list-style: none; margin-left: 0; padding-left: 1em; text-indent: -1em;\"><li class=\"li-check\">Set your main health goal</li><li class=\"li-check\">Review your current habits</li><li class=\"li-check\">Identify your first action step</li><li class=\"li-check\">Select your ideal coaching subscription</li></ul></div>",
          buttonText: "Let's Get Started",
          attachment: {
            type: 'image',
            url: heroImage,
          },
          layout: 'float-right',
        },
      },
      {
        name: 'short-text',
        id: 'preferred_name',
        attributes: {
          label: "What's your preferred name?",
          required: true,
          description: 'Ramona will use this to personalize her communication with you.',
        },
      },
      {
        name: 'date',
        id: 'dob',
        attributes: {
          label: "What's your birthdate?",
          description: 'Knowing your age helps Ramona tailor recommendations to your life stage and health needs.',
          required: true,
        },
      },
      {
        name: 'multiple-choice',
        id: 'primary_goal',
        attributes: {
          label: "What's your primary health goal right now?",
          description:
            "Research shows people are more successful when focusing on one goal at a time. Choose the goal that feels most important to you right now.",
          required: true,
          verticalAlign: true,
          choices: [
            { value: 'lose_weight', label: 'Lose weight' },
            { value: 'gain_muscle', label: 'Gain muscle' },
            { value: 'improve_fitness', label: 'Improve overall fitness' },
            { value: 'reduce_stress', label: 'Reduce stress' },
            { value: 'improve_sleep', label: 'Improve sleep' },
            { value: 'eat_healthier', label: 'Eat healthier' },
            { value: 'other', label: 'Other' },
          ] as Choice[],
        },
      },
      ...(primaryGoal?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'other_goal',
              attributes: {
                label: 'Please specify your other health goal:',
                required: true,
              },
            },
          ]
        : []),
      {
        name: 'slider',
        id: 'motivation_level',
        attributes: {
          label: 'On a scale of 1-10, how motivated are you to achieve your health goal?',
          description: '1: Not motivated, 10: Highly motivated. Your honest answer helps Ramona provide the right level of support and encouragement.',
          min: 1,
          max: 10,
          step: 1,
        },
      },
      {
        name: 'multiple-choice',
        id: 'health_conditions',
        attributes: {
          label: 'Do you have any health conditions we should be aware of?',
          description:
            "Knowing about any health conditions ensures Ramona’s recommendations are safe and appropriate for your specific situation. Not sure? That's okay - you can select \"None of these\" now and update your profile later if needed.",
          multiple: true,
          required: true,
          verticalAlign: true,
          choices: [
            { value: 'heart_condition', label: 'Heart condition (e.g., heart attack, heart failure)' },
            { value: 'high_blood_pressure', label: 'High blood pressure' },
            { value: 'respiratory_condition', label: 'Respiratory condition (e.g., asthma, COPD)' },
            { value: 'diabetes', label: 'Diabetes (Type 1 or Type 2, or told you have high blood sugar)' },
            { value: 'other', label: 'Other condition requiring medical supervision' },
            { value: 'none', label: 'None of these' },
          ] as Choice[],
        },
      },
      ...(healthConditions?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'other_health_condition',
              attributes: {
                label: 'Please specify your other health condition:',
              },
            },
          ]
        : []), 
        {
          name: 'statement',
          id: 'lifestyle_intro',
          attributes: {
            label: "Let's look at your existing habits.",
            quotationMarks: false,
            description: "This helps Ramona identify what's working well and where you might benefit from support. Don't worry if you haven't started any of these habits yet  - Ramona will help you build healthy habits at your own pace.",
            attachment: {
              type: 'image',
              url: morningImage
            },
            layout: isMobile ? 'split-left' : 'float-left'
        },
        },
        {
          name: 'multiple-choice',
          id: 'physical_activity',
          attributes: {
            label: 'How often do you get at least 30 minutes of physical activity?',
            required: true,
            verticalAlign: true,
            choices: [
              { value: 'rarely', label: 'Rarely/Never' },
              { value: 'sometimes', label: 'Sometimes' },
              { value: 'most_days', label: 'Most days' },
              { value: 'daily', label: 'Every day' },
            ],
          },
        },
        {
            name: 'multiple-choice',
            id: 'cooking_frequency',
            attributes: {
              label: 'How often do you cook meals at home?',
              description:  'Instead of takeout, restaurants, or pre-made meals',
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'rarely', label: 'Rarely/Never' },
                { value: 'sometimes', label: 'Sometimes' },
                { value: 'most_days', label: 'Most days' },
                { value: 'daily', label: 'Every day' },
              ],
            },
          },
          {
            name: 'multiple-choice',
            id: 'sleep_quality',
            attributes: {
              label: 'How often do you get a full night\'s sleep (7-8 hours) without interruption?',
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'rarely', label: 'Rarely/Never' },
                { value: 'sometimes', label: 'Sometimes' },
                { value: 'most_days', label: 'Most days' },
                { value: 'daily', label: 'Every day' },
              ],
            },
          },
          {
            name: 'multiple-choice',
            id: 'stress_frequency',
            attributes: {
              label: 'How often do you practice stress-reduction techniques?',
              description: 'Examples: deep breathing, meditation, yoga, or quiet time',
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'rarely', label: 'Rarely/Never' },
                { value: 'sometimes', label: 'Sometimes' },
                { value: 'most_days', label: 'Most days' },
                { value: 'daily', label: 'Every day' },
              ],
            },
          },
          {
            name: 'statement',
            id: 'focus_area_intro',
            attributes: {
              label: "Thanks for sharing about your routine!",
              description: "Now it's time to think about what you'd like to improve. Let's choose where to begin and identify a specific action you can take this week to start making immediate progress toward your goal.",
              quotationMarks: false,
              attachment: {
                type: 'image',
                url: roadImage
              },
              layout: 'split-right'
          },
          },
          {
            name: 'multiple-choice',
            id: 'focus_area',
            attributes: {
              label: 'Which health practice feels most important to you right now?',
              description: "Starting with the one focus area you feel most ready to tackle can build momentum for other changes.",
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'eating', label: 'Nutrition' },
                { value: 'activity', label: 'Movement/Physical Activity' },
                { value: 'sleep', label: 'Sleep' },
                { value: 'stress', label: 'Stress Management' },
              ],
            },
          },
          {
            name: 'multiple-choice',
            id: 'next_step',
            attributes: {
              label: "Great! Building better habits in {{field:focus_area}} can make a big difference. Which action feels most doable this week? ",
              required: true,
              verticalAlign: true,
            choices: 
            focusArea?.includes('eating')
            ? [
    
                { value: 'replace_snack', label: 'Replace one processed snack with fruit or vegetables each day this week' },
                { value: 'read_labels', label: 'Read nutrition labels during my main grocery shopping trip this week' },
                { value: 'cook_low_sodium', label: 'Try one new healthy recipe at home this week' },
                { value: 'measure_portions', label: 'Measure portion sizes for one meal each day this week' },
                        { value: 'other', label: 'Other' },
                ]
            : focusArea?.includes('activity')
            ? [
                { value: 'walk_after_meal', label: 'Take a 10-minute walk after one meal each day this week' },
                { value: 'morning_stretch', label: 'Do 5 minutes of stretching each morning this week' },
                { value: 'park_farther', label: 'Park in the farthest row of parking spots to add at least 100 steps each workday this week' },
                { value: 'take_stairs', label: 'Take the stairs instead of elevator once daily this week' },
                { value: 'other', label: 'Other' },
                ]
            : focusArea?.includes('sleep')
            ? [
                { value: 'consistent_bedtime', label: 'Go to bed by 10:30 PM each night this week' },
                { value: 'wind_down_routine', label: 'Complete a 5-minute wind-down routine (e.g. change clothes, brush teeth, quick stretch) by 10 PM each night this week' },
                { value: 'devices_away', label: 'Turn off and place all devices outside my bedroom by 10 PM each night this week' },
                { value: 'bedroom_environment', label: 'Use blackout curtains or white noise machine my in bedroom each night this week' },
                { value: 'other', label: 'Other' },
                ]
            : focusArea?.includes('stress')
            ? [
                { value: 'deep_breaths', label: 'Take 3 deep breaths at set times (e.g. 9 AM, 1 PM, 4 PM) each day this week' },
                { value: 'workday_break', label: 'Take a 5-minute walking break immediately after lunch each workday this week' },
                { value: 'gratitude_practice', label: "Write down three things I'm grateful for each evening this week" },
                { value: 'calming_music', label: 'Listen to calming music during morning my coffee/tea preparation each day this week' },
                { value: 'other', label: 'Other' },
                ]:[]
          }
        },
        ...(nextStep?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'next_step_other',
              attributes: {
                label: "Please specify the first action you'd like to take this week:",
                required: true,
              },
            },
          ]
        : []),
        {
          name: 'multiple-choice',
          id: 'potential_obstacles',
          attributes: {
            label: 'What might stop you from taking this one small step?',
            description: 'Choose all that apply',
            multiple: true,
            verticalAlign: true,
            choices: [
              { value: 'time', label: 'Lack of time' },
              { value: 'energy', label: 'Lack of energy' },
              { value: 'stress', label: 'Stress or emotional factors' },
              { value: 'physical_limitations', label: 'Physical limitations or discomfort' },
              { value: 'resources', label: 'Lack of resources (e.g., equipment, healthy food options)' },
              { value: 'other', label: 'Other' },
            ] as Choice[],
          },
        },
        ...(potentialObstacles?.includes('other')
          ? [
              {
                name: 'short-text',
                id: 'potential_obstacles_other',
                attributes: {
                  label: 'Please specify the other potential obstacle:',
                  required: true,
                },
              },
            ]
          : []),
          {
            name: 'statement',
            id: 'next_step_celebrate',
            attributes: {
              label: "Excellent work, {{field:preferred_name}}!",
              description: "Finally, let's make sure Ramona can support you effectively as you work toward your goals. Just two more quick questions to ensure seamless communication between you and Ramona.",
              quotationMarks: false,
              attachment: {
                type: 'image',
                url: plannerImage
              },
              layout: 'split-left'
            },
          },      
        {
          name: 'custom_phone_block',
          id: 'phone-number',
          attributes: {
            label: 'What\'s your preferred cell phone number?',
            description:
              "This is the number Ramona will use to provide your personalized coaching, prompts, and 24/7 support.",
            required: true,
  
          },
        },
        {
          name: 'dropdown',
          id: 'time-zone',
          attributes: {
            label: 'What\'s your current timezone?',
            description:
              "This enables Ramona to reach out at appropriate times of day to support your goals.",
            required: true,
            choices: [
              { value: 'America/New_York', label: 'Eastern Time (ET)' },
              { value: 'America/Chicago', label: 'Central Time (CT)' },
              { value: 'America/Denver', label: 'Mountain Time (MT)' },
              { value: 'America/Phoenix', label: 'Mountain Time - Arizona (no DST)' },
              { value: 'America/Los_Angeles', label: 'Pacific Time (PT)' },
              { value: 'America/Anchorage', label: 'Alaska Time (AKT)' },
              { value: 'Pacific/Honolulu', label: 'Hawaii-Aleutian Time (HST)' },
              { value: 'America/Puerto_Rico', label: 'Atlantic Time (AT)' },
              { value: 'Pacific/Guam', label: 'Chamorro Time (ChST)' },
              { value: 'Pacific/Saipan', label: 'Northern Mariana Islands Time' }
            ],
            defaultValue: 'America/New_York',
          },
        },
        {
          name: 'multiple-choice',
          id: 'communication_style',
          attributes: {
            label: 'To make your coaching experience as effective as possible, tell us how you prefer to receive guidance and support.',
            required: true,
            verticalAlign: true,
            choices: [
              { value: 'motivational', label: 'Motivational and encouraging' },
              { value: 'factual', label: 'Factual and informative' },
              { value: 'nurturing', label: 'Nurturing and supportive' },
              { value: 'direct', label: 'Direct and to-the-point' },
              { value: 'mix', label: 'Mix of styles' },
            ] as Choice[],
          },
        },
        {
          name: 'statement',
          id: 'not_replacing_your_doctor',
          attributes: {
            label: "Important Note",
            quotationMarks: false,
            description: "This program complements but does not replace medical care. Always consult your healthcare provider about changes to your health routine.",
            buttonText: "I Understand"
        },
        },
        {
        name: 'statement',
        id: 'thank_you',
        attributes: {
          label: "Let's save this progress, {{field:preferred_name}}.",
          quotationMarks: false,
          description: `<p>Thank you for completing your <i>Dial-In Profile</i>. We'll use your responses to:</p>
            <ul style="list-style-type: none; padding-left: 20px; margin-top: 5px; margin-bottom: 10px;">
            <li style="margin-left: -15px; padding-left:25px; position: relative"><span style="color: #CA6702; position: absolute; left: 0;">✔</span> Recommend your right-sized coaching subscription</li>
            <li style="margin-left: -15px; padding-left:25px; position: relative"><span style="color: #CA6702; position: absolute; left: 0;">✔</span> Start personalizing Ramona's coaching approach</li>
            <li style="margin-left: -15px; padding-left:25px; position: relative"><span style="color: #CA6702; position: absolute; left: 0;">✔</span> Help you build lasting habits that support your goals</li> </ul>
            Next step: Create your account so we can save your results and begin your journey.`,
          buttonText: "Save & Continue →"
        },
      },

    ],
    theme: {
      font: 'Urbanist, sans-serif',
      backgroundColor: '#F9F7F3',
      buttonsBgColor: '#005f73',
      answersColor: '#001219',
      questionsColor: '#595959',
      buttonsFontColor: '#ffffff',
      buttonsBorderRadius: 4,
      errorsFontColor: '#ae2012',
      errorsBgColor: '#ffe6e6',
      progressBarFillColor: '#005f73',
      progressBarBgColor: '#e9ecef',
    },
    messages: {
      'label.button.ok': 'OK',
      'label.hintText.enter': 'press <strong>Enter ↵</strong>',
      'label.hintText.multipleSelection': 'Choose as many as you like',
      'block.dropdown.placeholder': 'Type or select an option',
      'block.dropdown.noSuggestions': 'No suggestions available',
      'block.shortText.placeholder': 'Type your answer here',
      'block.longText.placeholder': 'Type your answer here',
      'block.number.placeholder': 'Type your answer here',
      'block.email.placeholder': 'Type your email here',
      'block.defaultThankYouScreen.label': `<h3>You did it!</h3>`,
      'label.errorAlert.required': 'This field is required!',
      'label.errorAlert.email': 'Please enter a valid email address.',
      'label.errorAlert.number': 'Please enter a valid number.',
      'label.errorAlert.url': 'Please enter a valid URL.',
      'label.submitBtn': 'Save & Continue',
    },
    settings: {
      disableProgressBar: false,
      disableWheelSwiping: false,
      disableNavigationArrows: false,
      animationDirection: 'vertical',
      showQuestionsNumbers: false,
      showLettersOnAnswers: true,
    },
    customCSS: `
    li.li-check:before {
    content: "✓";
    display: block;
    float: left;
    width: 1.2em;
    color: #005F73;
}
    `,
  };

  if (isCreatingAccount) {
    return (
      <AccountCreationForm
        onAccountCreated={handleAccountCreated}
      />
    );
  }

  return (
    <div className={classes.formContainer}>
      <Form formId={1} formObj={formObj} onSubmit={handleSubmit} applyLogic={false} beforeGoingNext={beforeGoingNext} />
    </div>
  );
}
