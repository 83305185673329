import React, { useCallback, useEffect, useState } from 'react';
import { Form, useFieldAnswer } from '@quillforms/renderer-core';
import type { BlockAttributes } from '@quillforms/types';
import { registerCoreBlocks } from '@quillforms/react-renderer-utils';
import '@quillforms/renderer-core/build-style/style.css';
import { createReference } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import { RegisterForm } from '@/components/CustomRegisterForm';
import { Patient, QuestionnaireResponse } from '@medplum/fhirtypes';
import '../../blocks/phoneblock';
import '../../blocks/USMailingAddress';
import { parseQuillFormAnswers } from '../../blocks/ParseQuillFormAnswers';
import classes from '../TestFormPage.module.css';
import { FormObj } from '@quillforms/renderer-core/build-types/types';
import { getConfig } from '../../config';
import { useNavigate, useSearchParams } from 'react-router-dom';

import heroImage from '@/img/profile/ramona-hero.webp';
import startImage from '@/img/profile/entering-health-profile-ramona-ai.webp';
import comparisonImage from '@/img/profile/ramona-wellness-coaching-comparison.webp';
import roadImage from '@/img/profile/forest-trail-walk-sunrise-ramona-ai.webp';
import plannerImage from '@/img/profile/planner-in-kitchen-ramona-ai.webp';
import morningImage from '@/img/profile/morning-routine-newspaper-breakfast-ramona-ai.webp';
import { useMatomo } from '@/common/useMatomo';

import { Box, Grid } from '@mantine/core';


interface SubmissionDispatchers {
  completeForm: () => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setSubmissionErr: (error: string) => void;
}

type Choice = {
  value: string;
  label: string;
};

type BeforeGoingNextParams = {
  setIsFieldValid: (id: string, flag: boolean) => void;
  setFieldValidationErr: (id: string, err: string) => void;
  setIsPending: (flag: boolean) => void;
  setIsCurrentBlockSafeToSwipe: (flag: boolean) => void;
  goToBlock: (id: string) => void;
  goNext: () => void;
  currentBlockId: string;
  answers: Record<string, any>;
};

function AccountCreationForm({
  onAccountCreated,
}: {
  onAccountCreated: () => void;
}): JSX.Element {
  const [error, setError] = useState('');
  const medplum = useMedplum();
  const config = getConfig();

  const handleRegister = () => {
    onAccountCreated();  
  };

  return (
    <Grid> 
      <Grid.Col span="auto">
        <Box pt={100} pb={200} px={10}>
          <RegisterForm
            type="patient"
            projectId={config.projectId}
            googleClientId={config.googleClientId}
            recaptchaSiteKey={config.recaptchaSiteKey}
            onSuccess={() => handleRegister()}
          >
            <h3>Let's Create Your Account</h3>
          </RegisterForm>
        </Box>
      </Grid.Col>
    </Grid>
  );
}

// Add this custom hook at the beginning of the file, before the component definitions
function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}

// Add this helper function before your component
const getFocusAreaDisplay = (focusArea: string | undefined): string => {
  if (!focusArea) return '';
  
  switch (focusArea) {
    case 'eating':
      return 'nutrition';
    case 'activity':
      return 'movement and physical activity';
    case 'sleep':
      return 'sleep';
    case 'stress':
      return 'stress management';
    case 'alcohol':
      return 'alcohol reduction';
    case 'monitoring':
      return 'blood pressure monitoring';
    case 'medication':
      return 'medication routine';
    default:
      return focusArea.toLowerCase();
  }
};

export function HealthyHeartSurveyPage(): JSX.Element {
  const medplum = useMedplum();
  const config = getConfig();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const { trackFormEvent } = useMatomo();

  const [surveyData, setSurveyData] = useState<Record<string, any> | null>(null);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);

  const primaryMotivation = useFieldAnswer('primary_motivation') as string | undefined;
  const motivationLevel = useFieldAnswer('motivation_level') as number | undefined;
  const medicationStatus = useFieldAnswer('medication_status') as string[] | undefined;
  const healthConditions = useFieldAnswer('health_conditions') as string[] | undefined;
  const focusArea = useFieldAnswer('focus_area') as string[] | undefined;
  const currentDiet = useFieldAnswer('current_diet') as string[] | undefined;
  const nextStep = useFieldAnswer('next_step') as string[] | undefined;
  const potentialObstacles = useFieldAnswer('potential_obstacles') as string[] | undefined;
  const sleepQuality = useFieldAnswer('sleep_quality') as string | undefined;
  const physicalActivity = useFieldAnswer('physical_activity') as string | undefined;
  const stressFrequency = useFieldAnswer('stress_frequency') as string | undefined;
  const alcoholConsumption = useFieldAnswer('alcohol_consumption') as string | undefined;
  const monitoringFrequency = useFieldAnswer('monitoring_frequency') as string | undefined;
  const medicationAdherence = useFieldAnswer('medication_adherence') as string | undefined;

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 768; // mobile only breakpoint for now


  useEffect(() => {
    if (queryString && medplum.getProfile()) {
      const patient = medplum.getProfile() as Patient;
      const updatedPatient = {
        ...patient,
        extension: [
          ...(patient.extension || []),
          {
            url: 'http://ramona.care/fhir/StructureDefinition/query-string',
            valueString: queryString,
          },
        ],
      };
      medplum
        .updateResource(updatedPatient)
        .then(() => {
          console.log('Patient resource updated successfully');
        })
        .catch((error) => {
          console.error('Error updating patient resource:', error);
        });
    }
  }, [queryString, medplum]);

  const handleSubmit = useCallback(
    (data: Record<string, any>, dispatchers: SubmissionDispatchers) => {
      const { completeForm, setIsSubmitting, setSubmissionErr } = dispatchers;

      console.log('Submitted answers:', data);

      const profile = medplum.getProfile();

      if (profile) {
        const patientReference = createReference(profile as Patient);
        const itemList = parseQuillFormAnswers(data.answers, formObj.blocks);
        const newResponse: QuestionnaireResponse = {
          resourceType: 'QuestionnaireResponse',
          status: 'completed',
          item: itemList,
          questionnaire: `Questionnaire/${config.onboardingId}`,
          subject: patientReference,
          source: patientReference,
          authored: new Date().toISOString(),
          author: patientReference,
        };

        setIsSubmitting(true);
        medplum
          .createResource(newResponse)
          .then((response) => {
            console.log('Created QuestionnaireResponse:', response);
            setIsSubmitting(false);
            completeForm();
            navigate('/select-plan');
          })
          .catch((error) => {
            console.error('Error creating QuestionnaireResponse:', error);
            setSubmissionErr('An error occurred while submitting the form.');
            setIsSubmitting(false);
          });
      } else {
        setSurveyData(data);
        setIsCreatingAccount(true);
      }
    },
    [medplum, config.onboardingId, navigate]
  );

  const handleAccountCreated = useCallback(() => {
    const profile = medplum.getProfile() as Patient;
    if (profile && surveyData) {
      const patientReference = createReference(profile);
      const itemList = parseQuillFormAnswers(surveyData.answers, formObj.blocks);
      itemList.push({
        linkId: "primary_goal",
        text: "What's your primary health goal right now?",
        answer: [
          {
            valueString: "Manage blood pressure"
          }
        ]
      });
      const newResponse: QuestionnaireResponse = {
        resourceType: 'QuestionnaireResponse',
        status: 'completed',
        item: itemList,
        questionnaire: `Questionnaire/${config.onboardingId}`,
        subject: patientReference,
        source: patientReference,
        authored: new Date().toISOString(),
        author: patientReference,
      };

      medplum
        .createResource(newResponse)
        .then((response) => {
          console.log('Created QuestionnaireResponse:', response);
          setIsCreatingAccount(false);
          navigate('/select-plan');
        })
        .catch((error) => {
          console.error('Error creating QuestionnaireResponse:', error);
        });
    }
  }, [medplum, surveyData, config.onboardingId, navigate]);

  const beforeGoingNext = ({
    setIsFieldValid,
    setFieldValidationErr,
    setIsPending,
    setIsCurrentBlockSafeToSwipe,
    goToBlock,
    goNext,
    currentBlockId,
    answers,
  }: BeforeGoingNextParams): void => {

    let currentAnswer = Array.isArray(answers[currentBlockId]?.value) ? answers[currentBlockId]?.value.join('') : answers[currentBlockId]?.value;

    // Track the new block view
    const currentBlock = formObj.blocks.find(block => block.id === currentBlockId);
    if (currentBlock) {
      trackFormEvent(
        'Form Navigation',
        'BlockAnswer',
        `${currentBlockId}|${currentBlock.name}`,
        currentAnswer as string || 'null-value'
      );
    }

    const setFieldValid = (): void => {
      setIsFieldValid(currentBlockId, true);
      setFieldValidationErr(currentBlockId, '');
      setIsCurrentBlockSafeToSwipe(true);
    };

    const setFieldInvalid = (errorMessage: string): void => {
      setIsFieldValid(currentBlockId, false);
      setFieldValidationErr(currentBlockId, errorMessage);
      setIsCurrentBlockSafeToSwipe(false);
    };

    switch (currentBlockId) {
      case 'age':
        const age = answers[currentBlockId].value as number;
        if (age < 18) {
          setFieldInvalid('You must be 18 or older to use this service.');
        } else {
          setFieldValid();
          goNext();
        }
        break;

      default:
        setFieldValid();
        goNext();
    }
  };

  const formObj: FormObj = {
    blocks: [
      {
        name: 'welcome-screen',
        id: 'welcome',
        attributes: {
          label: 'Welcome to Your 5-Minute <i>Dial-In Profile</i>',
          description:
            "In the next few minutes, we'll help you:<div><ul style=\"text-align: start; margin-left:20px; list-style: none; margin-left: 0; padding-left: 1em; text-indent: -1em;\"><li class=\"li-check\">Set your main health goal</li><li class=\"li-check\">Review your current habits</li><li class=\"li-check\">Identify your first action step</li><li class=\"li-check\">Select your ideal coaching subscription</li></ul></div>",
          buttonText: "Let's Get Started",
          attachment: {
            type: 'image',
            url: heroImage,
          },
          layout: 'float-right',
        },
      },
      {
        name: 'short-text',
        id: 'preferred_name',
        attributes: {
          label: "What's your preferred name?",
          required: true,
          description: 'Ramona will use this to personalize her communication with you.',
        },
      },
      {
        name: 'date',
        id: 'dob',
        attributes: {
          label: "What's your birthdate?",
          description: 'Knowing your age helps Ramona tailor recommendations to your life stage and health needs.',
          required: true,
        },
      },
      {
        name: 'multiple-choice',
        id: 'primary_motivation',
        attributes: {
          label: "What is your main goal for your blood pressure?",
          required: true,
          verticalAlign: true,
          choices: [
            { value: 'prevent_hbp', label: "I want to prevent high blood pressure " },
            { value: 'prevent_meds', label: 'I want to lower my blood pressure without medication' },
            { value: 'support_treatment', label: 'I want support alongside current medical treatment to improve my blood pressure' },
            { value: 'other', label: 'Other' },
          ],
        },
      },
      ...(primaryMotivation?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'primary_motivation_other',
              attributes: {
                label: 'Please specify your other goal for your blood pressure:',
                required: true,
              },
            },
          ]
        : []),
      {
        name: 'slider',
        id: 'motivation_level',
        attributes: {
          label: 'How motivated are you to work toward this goal?',
          description: '1: Not Motivated, 10: Highly Motivated. \n\nYour honest answer helps Ramona provide the right level of support and encouragement.',
          min: 1,
          max: 10,
          step: 1,
        },
      },
      {
        name: 'statement',
        id: 'goal_specific_questions',
        attributes: {
          label: "Great start, {{field:preferred_name}}!",
          description:"Now, let's get a snapshot of your health to ensure your goals and recommended actions align safely with any current medical care.",
          quotationMarks: false,
          attachment: {
            type: 'image',
            url: startImage
          },
          layout: isMobile ? 'split-left' : 'float-left'
        },
      },
      {
        name: 'multiple-choice',
        id: 'diagnosis_status',
        attributes: {
          label: 'Do you currently have high blood pressure?',
          description: 'High is 130/80 or above. Borderline (elevated) is 120-129/80. Normal blood pressure is below 120/80.',
          required: true,
          verticalAlign: true,
          choices: [
            { value: 'recent', label: 'Yes, I was diagnosed with high blood pressure within the last year' },
            { value: 'past', label: 'Yes, I was diagnosed with high blood pressure over a year ago' },
            { value: 'borderline', label: 'Not diagnosed, but my readings are borderline or high' },
            { value: 'prevention', label: "No, I have normal readings and I am focusing on prevention" },
            { value: 'unknown', label: "Not sure" },
          ],
        },
      },
      {
        name: 'multiple-choice',
        id: 'medication_status',
        attributes: {
          label: 'Are you taking any medications for blood pressure?',
          description: `It's helpful to know your medications so Ramona can suggest lifestyle changes that work safely alongside them.`,
          required: true,
          verticalAlign: true,
          choices: [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ],
        },
      },
      ...(medicationStatus?.includes('yes') ? [{
        name: 'group',
        id: 'medication_group',
        attributes: {
            label: 'Please list your medications.',
            description: 'Include name and dosage when possible.'
        },
        innerBlocks: [
            {
                name: 'short-text',
                id: 'medication_1',
                attributes: {
                label: 'Medication 1:',
                required: false,
                },
          },
          {
            name: 'short-text',
            id: 'medication_2',
            attributes: {
            label: 'Medication 2:',
            required: false,
            },
      },{
        name: 'short-text',
        id: 'medication_3',
        attributes: {
        label: 'Medication 3:',
        required: false,
        },
  },{
    name: 'short-text',
    id: 'medication_4',
    attributes: {
    label: 'Medication 4:',
    required: false,
    },
}
    ]}
    ]
      :[]),
      {
        name: 'multiple-choice',
        id: 'health_conditions',
        attributes: {
          label: 'Do you have any of these conditions?',
          description: `These conditions often influence your blood pressure and each other, and managing one condition well typically helps improve the others. Not sure? That's okay - you can select "None of these" now and update your profile later if needed.`,
          multiple: true,
          verticalAlign: true,
          required: true,
          choices: [
            { value: 'heart_disease', label: 'Heart disease (such as heart attack, heart failure, irregular heartbeat)' },
            { value: 'diabetes', label: 'Diabetes (Type 1 or Type 2, or told you have high blood sugar)' },
            { value: 'kidney_disease', label: 'Kidney disease (reduced kidney function or told you have kidney problems)' },
            { value: 'high_cholesterol', label: 'High cholesterol (or taking cholesterol medication)' },
            { value: 'other', label: 'Other important condition' },
            { value: 'none', label: 'None of these' },
          ],
        },
      },
      ...(healthConditions?.includes('other')
        ? [
            {
              name: 'group',
        id: 'conditions_group',
        attributes: {
            label: 'Please list your other medical conditions.'
        },
        innerBlocks: [
          {
              name: 'short-text',
              id: 'condition_1',
              attributes: {
              label: 'Condition 1:',
              required: false,
              },
          },
          {
            name: 'short-text',
            id: 'condition_2',
            attributes: {
            label: 'Condition 2:',
            required: false,
            },
          },
          {
            name: 'short-text',
            id: 'condition_3',
            attributes: {
            label: 'Condition 3:',
            required: false,
            },
          },
          {
            name: 'short-text',
            id: 'condition_4',
            attributes: {
            label: 'Condition 4:',
            required: false,
            },
          }
    ]}
          ]
        : []),
        {
          name: 'statement',
          id: 'lifestyle_intro',
          attributes: {
            label: "Next, let's look at your existing habits in key heart health practices.",
            quotationMarks: false,
            description: "This helps Ramona identify what's working well and where you might benefit from support. Don't worry if you haven't started any of these habits yet  - Ramona will help you build healthy habits at your own pace.",
            attachment: {
              type: 'image',
              url: morningImage
            },
            layout: isMobile ? 'split-left' : 'float-left'
        },
        },
        {
          name: 'multiple-choice',
          id: 'physical_activity',
          attributes: {
            label: 'How often do you get at least 30 minutes of physical activity?',
            description: `<i>Regular physical movement can help lower blood pressure.</i>`,
            required: true,
            verticalAlign: true,
            choices: [
              { value: 'rarely', label: 'Rarely/Never' },
              { value: 'sometimes', label: 'Sometimes' },
              { value: 'most_days', label: 'Most days' },
              { value: 'daily', label: 'Every day' },
            ],
          },
        },
        {
            name: 'multiple-choice',
            id: 'cooking_frequency',
            attributes: {
              label: 'How often do you cook meals at home?',
              description:  'Instead of takeout, restaurants, or pre-made meals.\n\n<i>Home-cooked meals typically have 50% less sodium than restaurant food.</i>',
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'rarely', label: 'Rarely/Never' },
                { value: 'sometimes', label: 'Sometimes' },
                { value: 'most_days', label: 'Most days' },
                { value: 'daily', label: 'Every day' },
              ],
            },
          },
          {
            name: 'multiple-choice',
            id: 'sleep_quality',
            attributes: {
              label: 'How often do you get a full night\'s sleep (7-8 hours) without interruption?',
              description: `<i>Good sleep can reduce blood pressure by 2-5 points overnight.</i>`,
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'rarely', label: 'Rarely/Never' },
                { value: 'sometimes', label: 'Sometimes' },
                { value: 'most_days', label: 'Most days' },
                { value: 'daily', label: 'Every day' },
              ],
            },
          },
          {
            name: 'multiple-choice',
            id: 'stress_frequency',
            attributes: {
              label: 'How often do you practice stress-reduction techniques?',
              description: 'Examples: deep breathing, meditation, yoga, or quiet time\n\n<i>Relaxation techniques can lower systolic pressure by 2-4 points.</i>',
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'rarely', label: 'Rarely/Never' },
                { value: 'sometimes', label: 'Sometimes' },
                { value: 'most_days', label: 'Most days' },
                { value: 'daily', label: 'Every day' },
              ],
            },
          },
          {
            name: 'multiple-choice',
            id: 'alcohol_consumption',
            attributes: {
              label: 'How often do you consume alcoholic beverages?',
              description: `Examples: beer, wine, or spirits.\n\n<i>Limiting alcohol can lower blood pressure by several points.</i>`,
              required: true,
              verticalAlign: true,
              choices: [
                { value: 'rarely', label: 'Rarely/Never' },
                { value: 'sometimes', label: 'Sometimes' },
                { value: 'most_days', label: 'Most days' },
                { value: 'daily', label: 'Every day' },
              ] as Choice[],
            },
          },
      {
        name: 'multiple-choice',
        id: 'monitoring_frequency',
        attributes: {
          label: 'How often do you check your blood pressure at home?',
          description: '<i>Regular monitoring may help you spot patterns in what moves your numbers.</i>',
          verticalAlign: true,
          required: true,
          choices: [
            { value: 'rarely', label: 'Rarely/Never' },
            { value: 'sometimes', label: 'Sometimes' },
            { value: 'most_days', label: 'Most days' },
            { value: 'daily', label: 'Every day' },
          ],
        },
      },
      ...(medicationStatus?.includes('yes') ? 
      [{
        name: 'multiple-choice',
        id: 'medication_adherence',
        attributes: {
          label: 'How often do you take all your medications exactly as prescribed?',
          description: '(Right time, right dose)\n\n<oTaking blood pressure medication consistently helps prevent dangerous spikes.',
          verticalAlign: true,
          required: true,
          choices: [
            { value: 'rarely', label: 'Rarely/Never' },
            { value: 'sometimes', label: 'Sometimes' },
            { value: 'most_days', label: 'Most days' },
            { value: 'daily', label: 'Every day' },
          ],
        }}]
      : []),
     
          {
            name: 'statement',
            id: 'focus_area_intro',
            attributes: {
              label: "Thanks for sharing about your routine!",
              description: "Now it's time to think about what you'd like to improve. Let's choose where to begin and identify a specific action you can take this week to start making immediate progress toward your goal.",
              quotationMarks: false,
              attachment: {
                type: 'image',
                url: roadImage
              },
              layout: 'split-right'
          },
          },
      {
        name: 'multiple-choice',
        id: 'focus_area',
        attributes: {
          label: 'Which area would you like to work on first?',
          description: "We'll focus on what feels most meaningful and doable for you.",
          required: true,
          verticalAlign: true,
          choices: [
            { value: 'eating', label: 'Nutrition' },
            ...((physicalActivity?.includes('rarely') || physicalActivity?.includes('sometimes')|| physicalActivity?.includes('most_days'))?[
              { value: 'activity', label: 'Movement/Physical Activity' },
            ]:[]),
            ...((sleepQuality?.includes('rarely') || sleepQuality?.includes('sometimes')|| sleepQuality?.includes('most_days'))?[
              { value: 'sleep', label: 'Sleep' },
            ]:[]),
            ...((stressFrequency?.includes('rarely') || stressFrequency?.includes('sometimes')|| stressFrequency?.includes('most_days'))?[
            { value: 'stress', label: 'Stress Management' },
            ]:[]),
            ...((alcoholConsumption?.includes('sometimes') || alcoholConsumption?.includes('most_days')|| alcoholConsumption?.includes('daily'))?[
            { value: 'alcohol', label: 'Alcohol Reduction' },
            ]:[]),
            ...((monitoringFrequency?.includes('rarely') || monitoringFrequency?.includes('sometimes'))?[
            { value: 'monitoring', label: 'Blood pressure monitoring' },
            ]:[]),   
            ...((medicationAdherence?.includes('rarely') || medicationAdherence?.includes('sometimes')|| medicationAdherence?.includes('most_days'))?[
            { value: 'medication', label: 'Medication Routine' },
            ]:[]),  
            
          ],
        },

      },
     {
        name: 'multiple-choice',
        id: 'next_step',
        attributes: {
          label: `Great! Building better habits in ${getFocusAreaDisplay(focusArea?.[0])} can make a big difference. Which action feels most doable this week?`,
          required: true,
          verticalAlign: true,
        choices: 
        focusArea?.includes('eating')
        ? [
    
          { value: 'replace_snack', label: 'Replace one processed snack with fruit or vegetables each day this week' },
          { value: 'read_labels', label: 'Read nutrition labels during my main grocery shopping trip this week' },
          { value: 'cook_low_sodium', label: 'Try one new healthy recipe at home this week' },
          { value: 'measure_portions', label: 'Measure portion sizes for one meal each day this week' },
                  { value: 'other', label: 'Other' },
          ]
        : focusArea?.includes('activity')
        ? [
          { value: 'walk_after_meal', label: 'Take a 10-minute walk after one meal each day this week' },
          { value: 'morning_stretch', label: 'Do 5 minutes of stretching each morning this week' },
          { value: 'park_farther', label: 'Park in the farthest row of parking spots to add at least 100 steps each workday this week' },
          { value: 'take_stairs', label: 'Take the stairs instead of elevator once daily this week' },
          { value: 'other', label: 'Other' },
          ]
        : focusArea?.includes('sleep')
        ? [
          { value: 'consistent_bedtime', label: 'Go to bed by 10:30 PM each night this week' },
          { value: 'wind_down_routine', label: 'Complete a 5-minute wind-down routine (e.g. change clothes, brush teeth, quick stretch) by 10 PM each night this week' },
          { value: 'devices_away', label: 'Turn off and place all devices outside my bedroom by 10 PM each night this week' },
          { value: 'bedroom_environment', label: 'Use blackout curtains or white noise machine my in bedroom each night this week' },
          { value: 'other', label: 'Other' },
          ]
        : focusArea?.includes('stress')
        ? [
          { value: 'deep_breaths', label: 'Take 3 deep breaths at set times (e.g. 9 AM, 1 PM, 4 PM) each day this week' },
          { value: 'workday_break', label: 'Take a 5-minute walking break immediately after lunch each workday this week' },
          { value: 'gratitude_practice', label: "Write down three things I'm grateful for each evening this week" },
          { value: 'calming_music', label: 'Listen to calming music during morning my coffee/tea preparation each day this week' },
          { value: 'other', label: 'Other' },
          ]
        : focusArea?.includes('monitoring')
        ? [
            { value: 'morning_bp_check', label: 'Check your BP once each morning this week' },
            { value: 'bp_log', label: 'Record your BP readings in a simple log immediately after each morning check this week' },
            { value: 'learn_bp_technique', label: "Review Ramona’s guide to measuring your blood pressure at home and practice the correct technique during each morning’s reading this week" },
            { value: 'quiet_before_bp', label: 'Using a timer, sit quietly for 3 minutes before each morning BP check this week' },
            { value: 'other', label: 'Other' },
            ]
        : focusArea?.includes('medication')
        ? [
            { value: 'med_reminder', label: 'Set up daily medication reminders on my phone for 8 AM and 8 PM by tomorrow evening' },
            { value: 'pill_organizer', label: 'Use a pill organizer for the week' },
            { value: 'visible_meds', label: "Place the next day's medications by the coffee maker or tea kettle each evening by 8 PM this week" },
            { value: 'med_log', label: 'Record each medication dose as I take it in a simple log, including time taken and any missed doses, each day this week' },
            { value: 'other', label: 'Other' },
            ]
        : focusArea?.includes('alcohol')
        ? [
            { value: 'replace_drink', label: 'Replace evening alcoholic drink with sparkling water on three weeknights' },
            { value: 'alcohol_free_days', label: 'Plan and complete two alcohol-free days' },
            { value: 'measure_drinks', label: 'Measure each alcoholic drink using a standard measuring shot glass or jigger and record in a drink log' },
            { value: 'non_drinking_stress_relief', label: 'Do 10 minutes of gentle stretching or deep breathing instead of drinking each evening' },
            { value: 'other', label: 'Other' },
            ]:[]
    }
},
      
      ...(nextStep?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'next_step_other',
              attributes: {
                label: "Please specify the other small step you'd like to take:",
                required: true,
              },
            },
          ]
        : []),
      {
        name: 'multiple-choice',
        id: 'potential_obstacles',
        attributes: {
          label: 'What might stop you from taking this one small step?',
          description: 'Choose all that apply',
          multiple: true,
          verticalAlign: true,
          choices: [
            { value: 'time', label: 'Lack of time' },
            { value: 'energy', label: 'Lack of energy' },
            { value: 'stress', label: 'Stress' },
            { value: 'physical_limitations', label: 'Physical limitations' },
            { value: 'resources', label: 'Lack of equipment' },
            { value: 'other', label: 'Other' },
          ] as Choice[],
        },
      },
      ...(potentialObstacles?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'potential_obstacles_other',
              attributes: {
                label: 'Please specify the other potential obstacle:',
                required: true,
              },
            },
          ]
        : []),
        {
          name: 'statement',
          id: 'next_step_celebrate',
          attributes: {
            label: "Excellent work, {{field:preferred_name}}!",
            description: "Finally, let's make sure Ramona can support you effectively as you work toward your goals. Just a few more quick questions to ensure seamless communication between you and Ramona.",
            quotationMarks: false,
            attachment: {
              type: 'image',
              url: plannerImage
            },
            layout: 'split-left'
          },
        },
        {
          name: 'multiple-choice',
          id: 'communication_style',
          attributes: {
            label: 'To make your coaching experience as effective as possible, tell us how you prefer to receive guidance and support.',
            required: true,
            verticalAlign: true,
            choices: [
              { value: 'motivational', label: 'Motivational and encouraging' },
              { value: 'factual', label: 'Factual and informative' },
              { value: 'nurturing', label: 'Nurturing and supportive' },
              { value: 'direct', label: 'Direct and to-the-point' },
              { value: 'mix', label: 'Mix of styles' },
            ] as Choice[],
          },
        },
      {
        name: 'custom_phone_block',
        id: 'phone-number',
        attributes: {
          label: 'What\'s your preferred cell phone number?',
          description:
            "This is the number Ramona will use to provide your personalized coaching, check-ins, and 24/7 support.",
          required: true,

        },
      },
      {
        name: 'dropdown',
        id: 'time-zone',
        attributes: {
          label: 'What\'s your current timezone?',
          description:
            "This enables Ramona to reach out at appropriate times of day to support your goals.",
          required: true,
          choices: [
            { value: 'America/New_York', label: 'Eastern Time (ET)' },
            { value: 'America/Chicago', label: 'Central Time (CT)' },
            { value: 'America/Denver', label: 'Mountain Time (MT)' },
            { value: 'America/Phoenix', label: 'Mountain Time - Arizona (no DST)' },
            { value: 'America/Los_Angeles', label: 'Pacific Time (PT)' },
            { value: 'America/Anchorage', label: 'Alaska Time (AKT)' },
            { value: 'Pacific/Honolulu', label: 'Hawaii-Aleutian Time (HST)' },
            { value: 'America/Puerto_Rico', label: 'Atlantic Time (AT)' },
            { value: 'Pacific/Guam', label: 'Chamorro Time (ChST)' },
            { value: 'Pacific/Saipan', label: 'Northern Mariana Islands Time' }
          ],
          defaultValue: 'America/New_York',
        },
      },
      {
        name: 'statement',
        id: 'not_replacing_your_doctor',
        attributes: {
          label: "Important Note",
          quotationMarks: false,
          description: "This program complements but does not replace medical care. Always consult your healthcare provider about changes to your health routine.",
          buttonText: "I Understand"
      },
      },
      {
        name: 'statement',
        id: 'thank_you',
        attributes: {
          label: "You did it, {{field:preferred_name}}!",
          quotationMarks: false,

          description: `<p>Thanks for taking time to Dial-In! We'll use your responses to:</p>
            <ul style="list-style-type: none; padding-left: 20px; margin-top: 5px; margin-bottom: 10px;">
            <li style="margin-left: -15px; padding-left:25px; position: relative"><span style="color: #CA6702; position: absolute; left: 0;">✔</span> Recommend your right-sized coaching subscription</li>
            <li style="margin-left: -15px; padding-left:25px; position: relative"><span style="color: #CA6702; position: absolute; left: 0;">✔</span> Start personalizing Ramona's coaching approach</li>
            <li style="margin-left: -15px; padding-left:25px; position: relative"><span style="color: #CA6702; position: absolute; left: 0;">✔</span> Help you build lasting habits that support your goals</li> </ul>
            <p>The next step is to create your account so we can save your results and get started.</p>`,
          nextBtnLabel: "Create Free Account"
        },
      },
    ],
    theme: {
      font: 'Urbanist, sans-serif',
      backgroundColor: '#F9F7Fs3',
      buttonsBgColor: '#005f73',
      answersColor: '#001219',
      questionsColor: '#595959',
      buttonsFontColor: '#ffffff',
      buttonsBorderRadius: 4,
      errorsFontColor: '#ae2012',
      errorsBgColor: '#ffe6e6',
      progressBarFillColor: '#005f73',
      progressBarBgColor: '#e9ecef',
    },
    messages: {
      'label.button.ok': 'OK',
      'label.hintText.enter': 'press <strong>Enter ↵</strong>',
      'label.hintText.multipleSelection': 'Choose as many as you like',
      'block.dropdown.placeholder': 'Type or select an option',
      'block.dropdown.noSuggestions': 'No suggestions available',
      'block.shortText.placeholder': 'Type your answer here',
      'block.longText.placeholder': 'Type your answer here',
      'block.number.placeholder': 'Type your answer here',
      'block.email.placeholder': 'Type your email here',
      'block.defaultThankYouScreen.label': `<h3>You did it!</h3>`,
      'label.errorAlert.required': 'This field is required!',
      'label.errorAlert.email': 'Please enter a valid email address.',
      'label.errorAlert.number': 'Please enter a valid number.',
      'label.errorAlert.url': 'Please enter a valid URL.',
      'label.submitBtn': 'Create Free Account',
    },
    settings: {
      disableProgressBar: false,
      disableWheelSwiping: false,
      disableNavigationArrows: false,
      animationDirection: 'vertical',
      showQuestionsNumbers: false,
      showLettersOnAnswers: true,
    },
    customCSS: `
    li.li-check:before {
    content: "✓";
    display: block;
    float: left;
    width: 1.2em;
    color: #005F73;
}
    .renderer-components-block-label {
      text-align: start!important;
    }
    .renderer-components-block-description {
      text-align: start!important;
    }
    .qf-welcome-screen-block__action {
      text-align: start!important;
    }
    `,
  };

  if (isCreatingAccount) {
    return (
      <AccountCreationForm
        onAccountCreated={handleAccountCreated}
      />
    );
  }

  return (
    <div className={classes.formContainer}>
      <Form formId={1} formObj={formObj} onSubmit={handleSubmit} applyLogic={false} beforeGoingNext={beforeGoingNext} />
    </div>
  );
}

export default HealthyHeartSurveyPage;