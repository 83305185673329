import React, { useEffect, useState } from 'react';
import { Container, Paper, Title, Text, Button, Stack, List, Box, Group, ThemeIcon } from '@mantine/core';
import { IconBarrierBlock, IconHeartHandshake, IconPhone, IconQuestionMark, IconStairs, IconTarget } from '@tabler/icons-react';
import { useMedplum, useMedplumProfile } from '@medplum/react';
import { getConfig } from '../../config';
import { Patient } from '@medplum/fhirtypes';

const FirstCallPage = () => {
  const [goal, setGoal] = useState<string | null>(null);
  const [task, setTask] = useState<string | null>(null);
  const [motivations, setMotivations] = useState<string[]>([]);
  const [obstacles, setObstacles] = useState<string[]>([]);
  const medplum = useMedplum();
  const config = getConfig();
  const ramonaPhone = config.ramonaPhoneNumber;
  const profile = useMedplumProfile() as Patient;


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Goal
        const goalResource = await medplum.searchOne('Goal', { patient: 'Patient/' + profile.id });
        if (goalResource?.description) {
          setGoal(goalResource.description.text as string);
        }

        // Fetch Task
        const taskResource = await medplum.searchOne('Task', {
          subject: 'Patient/' + profile.id,
          status: 'requested'
        });
        if (taskResource?.description) {
          setTask(taskResource.description);
        }

        // Fetch Observations
        const observations = await medplum.searchResources('Observation', {
          subject: 'Patient/' + profile.id,
          code: 'primary-motivation,potential-obstacles'
        });

        const obstacleObs = observations.filter(obs => 
          obs.code.coding?.[0]?.code === 'potential-obstacles'
        );

        setObstacles(
          obstacleObs
            .map(obs => obs.valueCodeableConcept?.coding?.[0]?.display)
            .filter(Boolean) as string[]
        );

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [medplum, profile.id]);

  return (
    <Container size="sm">
      <Paper shadow="md" p="xl" mt="xl">
        <Stack gap="xl">
          <Title order={2} ta="center">Make Your First Call with Ramona</Title>
          
          <Text ta="center">
            It's time to start your health journey with Ramona! We've already gathered some information to help guide your conversation:
          </Text>
          
<Box  p="xs" style={{ borderRadius: '8px' }}>
  <Stack gap="md">
    {[
      { title: 'Your main health goal', description: goal, icon: IconTarget },
      { title: 'Your first small step', description: task, icon: IconStairs },
      { title: 'Obstacles you\'re facing', description: Array.isArray(obstacles) ? obstacles.join(', ') : '', icon: IconBarrierBlock },
    ].map((item, index) => (
      <Group key={index} align="flex-start" wrap="nowrap">
        <ThemeIcon color="blue" size="lg" radius="lg" style={{ flexShrink: 0 }}>
          <item.icon size={18} />
        </ThemeIcon>
        <Stack gap={5}>
          <Text fw={700} size="lg">{item.title}</Text>
          <Text>{item.description || ''}</Text>
        </Stack>
      </Group>
    ))}
  </Stack>
</Box>
          
          <Text ta="center">
          Ramona will create your personalized health plan and guide you toward your goals. This call is a key milestone - she's ready to support your journey to better health.
          </Text>
          
          <Button 
            size="lg" 
            leftSection={<IconPhone />}
            onClick={() => window.location.href = `tel:${ramonaPhone}`}
          >
            Call (505) 4-RAMONA
          </Button>
          
          <Button 
                size="lg"
                color='grey' 
                leftSection={<IconHeartHandshake />}
                onClick={() => window.location.href = '/'}
              >
                First Call Complete
            </Button>
          
        </Stack>
      </Paper>
    </Container>
  );
};

export default FirstCallPage;
