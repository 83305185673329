import type { BlockAttributes, FormBlocks, FormBlock } from '@quillforms/types';

interface Choice {
  value: string;
  label: string;
}

interface FHIRQuestionnaireResponseItem {
  linkId: string;
  text?: string;
  answer?: FHIRQuestionnaireResponseItemAnswer[];
  item?: FHIRQuestionnaireResponseItem[];
}

interface FHIRQuestionnaireResponseItemAnswer {
  valueString?: string;
  valueInteger?: number;
  valueDecimal?: number;
  valueDate?: string;
  valueBoolean?: boolean;
  valueUri?: string;
  valueQuantity?: {
    value: number;
    unit?: string;
    system?: string;
    code?: string;
  };
  valueCodeableConcept?: {
    coding: Array<{
      system?: string;
      code: string;
      display: string;
    }>;
  };
}

type QuillFormAnswer = {
  value: any;
  isAnswered: boolean;
  blockName: string;
  attributes?: any;
  isValid?: boolean;
  isPending?: boolean;
  validationErr?: string;
  isCorrectIncorrectScreenDisplayed?: boolean;
  isLocked?: boolean;
};

export function parseQuillFormAnswers(
  answers: Record<string, QuillFormAnswer>,
  formBlocks: FormBlock[]
): FHIRQuestionnaireResponseItem[] {
  console.log('Starting parseQuillFormAnswers');
  console.log('Answers:', answers);
  console.log('Form Blocks:', formBlocks);

  return processBlocks(formBlocks, answers);
}

function processBlocks(blocks: FormBlock[], answers: Record<string, QuillFormAnswer>): FHIRQuestionnaireResponseItem[] {
  let items: FHIRQuestionnaireResponseItem[] = [];

  for (const block of blocks) {
    const item = processBlock(block, answers);
    if (item) {
      items.push(item);
    }
  }

  return items;
}

function processBlock(block: FormBlock, answers: Record<string, QuillFormAnswer>): FHIRQuestionnaireResponseItem | null {
  if (block.name === 'group') {
    const groupItem: FHIRQuestionnaireResponseItem = {
      linkId: block.id,
      text: block.attributes?.label || block.id,
      item: []  // Initialize with an empty array
    };

    if (block.innerBlocks) {
      groupItem.item = processBlocks(block.innerBlocks as FormBlocks, answers);
    }

    console.log(`post: ${groupItem}`);

    return (groupItem.item?.length ?? 0) > 0 ? groupItem : null;
  }

  const answer = answers[block.id];
  if (!answer || !answer.isAnswered && answer.value === undefined) {
    console.log(`Skipping unanswered question: ${block.id}`);
    return null;
  }

  const item: FHIRQuestionnaireResponseItem = {
    linkId: block.id,
    text: block.attributes?.label || block.id,
    answer: translateAnswer(answer, block)
  };

  console.log(`Translated item for linkId: ${block.id}`, item);
  return item;
}

function translateAnswer(answer: QuillFormAnswer, block: FormBlock): FHIRQuestionnaireResponseItemAnswer[] {
  switch (answer.blockName) {
    case 'short-text':
    case 'long-text':
      return [{ valueString: answer.value as string }];
    case 'number':
    case 'slider':
      return [{ valueDecimal: answer.value as number }];
    case 'date':
      return [{ valueDate: formatDate(answer.value as string) }];
    case 'email':
    case 'website':
      return [{ valueUri: answer.value as string }];
    case 'multiple-choice':
    case 'dropdown':
      if (block.id === 'time-zone') { // Special case for timezone
        if (Array.isArray(answer.value)) {
          return answer.value.map(val => ({
            valueString: val
          }));
        } else {
          return [{
            valueString: answer.value as string
          }];
        }
      } else { // All other multiple-choice/dropdown questions remain unchanged
        if (Array.isArray(answer.value)) {
          return answer.value.map(val => ({
            valueString: getChoiceLabel(block, val) || val
          }));
        } else {
          return [{
            valueString: getChoiceLabel(block, answer.value as string) || answer.value as string
          }];
        }
      }
    case 'custom_phone_block':
      return [{ valueString: answer.value as string }];
    default:
      console.warn(`Unsupported block type: ${answer.blockName}`);
      return [];
  }
}

function getChoiceLabel(block: FormBlock, value: string): string | undefined {
  const choices = block.attributes?.choices as Choice[] | undefined;
  return choices?.find((choice: Choice) => choice.value === value)?.label;
}

function formatDate(dateString: string): string {
  const [month, day, year] = dateString.split('/');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}
