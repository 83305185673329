import { 
  Container, Paper, Title, Text, Button, Stack, Group, ThemeIcon, 
  Card, Box, Badge, List, Grid,
  Divider,
  Loader
} from '@mantine/core';
import { Patient, Goal, Task, Observation, Condition, Account } from '@medplum/fhirtypes';
import { useMedplumProfile, useMedplum } from '@medplum/react';
import { IconChartBar, IconCheck, IconClock, IconStar, IconStarFilled, IconStarOff, IconTarget } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getConfig } from '@/config'
import { useSubscription } from '@/hooks/subscriptionManagement';
import CheckoutFlow, { PLAN_DATA, PlanType } from './CheckoutFlow';


interface Plan {
  id: string;
  name: string;
  price: string;
  annualPrice?: string;
  description: string;
  buttonText: string;
  trialText: string;
  features: { title: string; description: string }[];
  comparisonFeatures: { title: string; description?: string }[]; // 'description' might be optional
  highlightedFeature?: { title: string; description: string };
  stripeProductId?: string; // Add if you kept these in the plans array
  stripePriceId?: string;   // Add if you kept these in the plans array
  url?: string; // Add the 'url' property
}

const getGoalPresentation = (goalText: string) => {
  // Remove common prefixes to make it more natural
  return goalText.toLowerCase();
};

const getTaskPresentation = (taskText: string) => {
  // Make the task sound more natural and active
  return taskText.toLowerCase();
};

const SelectPlanPage = () => {
  const profile = useMedplumProfile() as Patient;
  const firstName = profile.name?.find(n => n.use === 'nickname')?.given?.[0] || 
                 profile.name?.[0]?.given?.[0] || 
                 '';
  const medplum = useMedplum();
  const config = getConfig();
  const environment = config.environment || 'staging';
  const email = profile.telecom?.find(t => t.system === 'email')?.value || '';
  const [goal, setGoal] = useState<Goal>();
  const [nextStep, setNextStep] = useState<Task>();
  const [recommendedPlan, setRecommendedPlan] = useState<string>('plus');
  const [accountId, setAccountId] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const { hasActivePlan, error } = useSubscription();
  const MAX_RETRIES = 5;
  const RETRY_DELAY = 2000;
  const [goalIsHeart, setGoalIsHeart] = useState(false);

    // Fetch the user's active goal and next step
    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
        
try {
          // Fetch all required data in parallel
        const [
          goals,
          tasks,
          accounts,
          motivationObs,
          conditions
        ] = await Promise.all([
          medplum.searchResources('Goal', { 
            patient: profile.id, 
            'lifecycle-status': ['active', 'accepted', 'planned'].join(','),
            _sort: '-_lastUpdated',
           }),
          medplum.searchResources('Task', {
            subject: `Patient/${profile.id}`,
            status: 'requested',
          }),
          medplum.searchResources('Account', {
            subject: `Patient/${profile.id}`,
            status: 'active',
            _count: '1'
          }),
          medplum.searchResources('Observation', {
            patient: profile.id,
            code: 'http://ramona.care|motivation-level',
            _sort: '-date',
            _count: '1'
          }),
          medplum.searchResources('Condition', {
            patient: profile.id,
            'clinical-status': 'active'
          })
        ]);
        

        if ((goals.length === 0 || tasks.length === 0 || accounts.length === 0 || motivationObs.length === 0
           ) && retryCount < MAX_RETRIES) { 
          // If missing anything, retry after delay. Note we don't know if condition is missing
          setTimeout(() => {
            setRetryCount(prev => prev + 1);
          }, RETRY_DELAY);
          medplum.invalidateAll();
          return;
        }
        setGoal(goals[0]);
        setNextStep(tasks[0]);
        setAccountId((accounts[0] as Account).id || '');


          // Determine recommended plan
          const motivationScore = (motivationObs[0] as Observation)?.valueInteger || 0;
          const conditionsCount = conditions.length;

          if (motivationScore >= 9 && conditionsCount === 0) {
            setRecommendedPlan('basic');
          } else if (
            (conditionsCount >= 3) 
          ) {
            setRecommendedPlan('pro');
          } else {
            setRecommendedPlan('plus');
          }
          // Avoid race condition on setGoal and fetch directly from object
          setGoalIsHeart(goals[0].description.text === 'Manage blood pressure' ? true : false);
          console.log(`goals: ${goals.length} : ${goals[0].description.text}`);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          if (retryCount < MAX_RETRIES) {
            // If error occurs, retry after delay
            setTimeout(() => {
              setRetryCount(prev => prev + 1);
            }, RETRY_DELAY);
            medplum.invalidateAll();
            return;
          }
        } finally {
          if (retryCount >= MAX_RETRIES) {
            setIsLoading(false);
          }
        }
      };
  
    fetchData();
    }, [medplum, profile.id, retryCount]);

    // Add error state handling if max retries reached
    if (!isLoading && !goal && retryCount >= MAX_RETRIES) {
      return (
        <Container size="md">
          <Paper shadow="md" px="xl" mt="lg">
            <Stack gap="xl" align="center" py="xl">
              <Text c="red">
                Unable to load your personalized recommendations. Please try refreshing the page.
              </Text>
            </Stack>
          </Paper>
        </Container>
      );
    }

    if (isLoading) {
      return (
        <Container size="md">
          <Paper shadow="md" px="xl" mt="lg">
            <Stack gap="xl" align="center" py="xl">
              <Text>Loading your personalized plan recommendations...</Text>
              <Loader size={15} />
              
            </Stack>
          </Paper>
        </Container>
      );
    }
    
    return (
      <Paper py='sm' bg='gray.3'>
        <Container size="xl">
          <Grid gutter="xl">
            {/* Left column - Personalized Header */}
            <Grid.Col span={{ base: 12, sm: 5 }}>
              <Stack gap="md" ta="center">
                {goal && nextStep && (
                  <Box bg="gray.0" p="lg" style={{ borderRadius: '8px' }}>
                    <Stack gap="md">
                      <Group align="center" justify="center">
                        <Text size="xl" fw={600}>Congrats {firstName}! 🎉</Text>
                      </Group>
                      
                      <Text size="lg" ta="center">
                        You're ready to {getGoalPresentation(goal.description.text ||'')}, 
                        and Ramona will start by helping you {getTaskPresentation(nextStep.description || '')}.
                      </Text>
                      
                      <Text size="lg" ta="center">
                        Based on your goals and Dial-In Profile responses, we recommend you start with our <strong>{recommendedPlan.charAt(0).toUpperCase()+recommendedPlan.slice(1)}</strong> plan
                        {goalIsHeart && <span> paired with the connected <strong>4G blood pressure cuff</strong> for easy monitoring</span>}.
                      </Text>
                    </Stack>
                  </Box>
                )}
              </Stack>
            </Grid.Col>

            {/* Right column - CheckoutFlow */}
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <CheckoutFlow 
                recommendedPlan={recommendedPlan as PlanType}
                recommendCuff={goalIsHeart}
                 />
            </Grid.Col>
          </Grid>
        </Container>
      </Paper>
    );
};

const stripeIds = {
  production: {
    basic: {
      productId: 'prod_RRYFfezEtyZ2AY',
      priceId: 'price_1QYf8aRwL3F8byZnVwZAqku9'
    },
    'plus': {
      productId: 'prod_RRYNibriCooeU3',
      priceId: 'price_1QYfH0RwL3F8byZn38OjEMr6'
    },
    pro: {
      productId: 'prod_RQPV9eudFJhlSy',
      priceId: 'price_1QYf95RwL3F8byZnTpAnKqAU'
    }
  },
  staging: {
    basic: {
      productId: 'prod_RTlKqeecwAAuOH',
      priceId: 'price_1Qano4RwL3F8byZnDpRnbszx'
    },
    'plus': {
      productId: 'prod_RQsR8hXK7vblpo',
      priceId: 'price_1QY0ghRwL3F8byZnHp6NNmwk'
    },
    pro: {
      productId: 'prod_RTlDGAbjo4ThEF',
      priceId: 'price_1QangjRwL3F8byZny3VBCS0j'
    }
  }
};


export default SelectPlanPage;